import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_pro.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Custom maps allow you to show selected content. No matter if it is a complete
overview with all your different activities, or only a single activity. Add
points of interest on the map to provide additional information to your guests.`}</p>
    <br />
    <p>{`Every map has its unique URL and a QR-code. Easily embed the interactive map
on your website, or connect print products with the digital content through
the QR-code, or share it in newsletters or social media.`}</p>
    <br />
    <p>{`Log in to `}<a parentName="p" {...{
        "href": "https://trailguide.net/portal"
      }}>{`https://trailguide.net/portal`}</a>{` - then go to the Collections manager.`}</p>
    <br />
    <Image src="pro/collections-manager.jpg" mdxType="Image" />
    <p>{`The collections manager shows your collections with sorting and searching
options. Click an existing collection to edit it or the button
“Add a new collection”.`}</p>
    <br />
    <Image src="pro/collections-editor.jpg" mdxType="Image" />
    <br />
    <p>{`You can select the content through 1) a search and filter, or 2) by
hand-picking the activities you want included.`}</p>
    <div className="flex">
  <Image className="m-2" src="pro/collection-search.jpg" mdxType="Image" />
  <Image className="m-2" src="pro/hand-picked.jpg" mdxType="Image" />
    </div>
    <br />
    <br />
    <Link className="float-left" to="/pro/transfer" mdxType="Link">
  ← Transfer your content to your destination
    </Link>
    <Link className="float-right" to="/pro/mapbyhand" mdxType="Link">
  Select activities by hand →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      